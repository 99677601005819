import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useRouter } from 'next/router';
import useApiClient from './apiClient';
import { useToast } from '@chakra-ui/react';

const initialState = {
  loading: false,
  loaded: false,
  hotel: null,
  preloadedHotel: null,
};

export const CurrentHotelContext = React.createContext([initialState]);

const LOADING_HOTEL = 'LOADING_HOTEL';
const LOADED_HOTEL = 'LOADED_HOTEL';
const PRELOADED_HOTEL = 'PRELOADED_HOTEL';
const RESET_HOTEL = 'RESET_HOTEL';

function currentHotelReducer(state, action) {
  switch (action.type) {
    case PRELOADED_HOTEL: {
      return {
        ...state,
        preloadedHotel: action.hotel,
      };
    }
    case RESET_HOTEL: {
      return {
        ...state,
        hotel: null,
        loaded: false,
        loading: false,
      };
    }
    case LOADING_HOTEL: {
      return {
        ...state,
        hotel: null,
        loaded: false,
        loading: true,
      };
    }
    case LOADED_HOTEL: {
      return {
        ...state,
        hotel: action.hotel,
        loaded: true,
        loading: false,
      };
    }
  }
}

export function CurrentHotel(props) {
  const [state] = useContext(CurrentHotelContext);

  if (!state.hotel || state.loading || !state.loaded) {
    return <p>Chargement</p>;
  }

  return <>{props.children}</>;
}

export function useCurrentHotel() {
  return useContext(CurrentHotelContext)[0].hotel;
}

export function usePreloadHotel() {
  const dispatch = useContext(CurrentHotelContext)[1];

  return useCallback((hotel) => {
    dispatch({ type: PRELOADED_HOTEL, hotel });
  }, []);
}

async function fetchHotel(apiClient, slug, preloadedHotel) {
  if (preloadedHotel && preloadedHotel.slug === slug) {
    return preloadedHotel;
  }

  const response = await apiClient(
    `/api/hotels/?filters[slug]=${encodeURIComponent(
      slug
    )}&items=1&includes=hasLibeoAPIConnexion,hasExthandActivated`
  );
  if (!response.ok) {
    throw new Error();
  }

  const data = await response.json();

  if (typeof data.items[0] === 'undefined') {
    throw new Error();
  }

  return data.items[0];
}

export function CurrentHotelProvider(props) {
  const [state, dispatch] = useReducer(currentHotelReducer, initialState);
  const apiClient = useApiClient();
  const toast = useToast();

  const currentHotelContext = useMemo(() => {
    return [state, dispatch];
  }, [state, dispatch]);

  const router = useRouter();

  useEffect(() => {
    if (!router.query.hotel) {
      dispatch({ type: RESET_HOTEL });
      return;
    }

    if (
      typeof state.hotel?.slug !== 'undefined' &&
      router.query.hotel === state.hotel.slug
    ) {
      // hotel already loaded
      return;
    }

    async function fetchCurrentHotel() {
      dispatch({ type: LOADING_HOTEL });
      let hotel = hotel;
      try {
        hotel = await fetchHotel(
          apiClient,
          router.query.hotel,
          state.preloadedHotel
        );
      } catch (error) {
        toast({
          title: "Cet hotel n'existe pas",
          status: 'error',
          isClosable: true,
        });
        router.push('/');
        return;
      }
      dispatch({ type: LOADED_HOTEL, hotel });
    }

    if (router.query.hotel !== state.hotel?.slug) {
      fetchCurrentHotel();
    }
  }, [router.query.hotel, state.hotel, dispatch]);

  return (
    <CurrentHotelContext.Provider value={currentHotelContext}>
      {props.children}
    </CurrentHotelContext.Provider>
  );
}
