import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useRouter } from 'next/router';

export const initialState = {
  component: null,
  pageProps: {},
  pageTitle: null,
  number: 0,
};

const RightPageContext = React.createContext(initialState);

const CLOSE_RIGHT_PAGE = 'CLOSE_RIGHT_PAGE';
export const OPEN_RIGHT_PAGE = 'OPEN_RIGHT_PAGE';

export function openRightPageAction(
  dispatch,
  component,
  pageTitle,
  pageProps = {}
) {
  dispatch({
    type: OPEN_RIGHT_PAGE,
    component,
    pageProps,
    pageTitle,
  });
}

export function closeRightPageAction(dispatch) {
  return dispatch({
    type: CLOSE_RIGHT_PAGE,
  });
}

export function rightPageReducer(state, action) {
  switch (action.type) {
    case CLOSE_RIGHT_PAGE:
      return initialState;
    case OPEN_RIGHT_PAGE:
      return {
        ...state,
        component: action.component,
        pageProps: action.pageProps,
        pageTitle: action.pageTitle,
        number: state.number + 1,
      };
  }
}

export function useRightPageDispatch() {
  return useContext(RightPageContext)[1];
}

export function useRightPageState() {
  return useContext(RightPageContext)[0];
}

export function useOpenRightPage() {
  const dispatch = useContext(RightPageContext)[1];

  return useCallback(
    (component, pageTitle, pageProps = {}) =>
      openRightPageAction(dispatch, component, pageTitle, pageProps),
    []
  );
}

export function useCloseRightPage() {
  const dispatch = useContext(RightPageContext)[1];

  return useCallback(() => closeRightPageAction(dispatch), []);
}

export default function RightPageProvider(props) {
  const [state, dispatch] = useReducer(rightPageReducer, initialState);
  const router = useRouter();

  const rightPageContext = useMemo(() => {
    return [state, dispatch];
  }, [state, dispatch]);

  useEffect(() => {
    router.events.on('routeChangeStart', () =>
      dispatch({ type: CLOSE_RIGHT_PAGE })
    );
  }, []);

  return (
    <RightPageContext.Provider value={rightPageContext}>
      {props.children}
    </RightPageContext.Provider>
  );
}
