import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

export const PRIMARY = 'primary';
export const PRIMARY_LIGHTER = 'primaryLighter';
export const SECONDARY = 'secondary';
export const SECONDARY_LIGHTER = 'secondaryLighter';
export const LIGHT = 'light';
export const DARK = 'dark';
export const DANGER = 'danger';
export const DANGER_LIGHTER = 'dangerLighter';
export const SUCCESS = 'success';
export const SUCCESS_LIGHTER = 'successLighter';
export const INFO = 'info';
export const INFO_LIGHTER = 'infoLighter';
export const WARNING = 'warning';
export const WARNING_LIGHTER = 'warningLighter';
export const DARK_GREY = 'darkGrey';
export const MID_GREY = 'midGrey';
export const LIGHT_MID_GREY = 'lightMidGrey';
export const LIGHT_GREY = 'lightGrey';
export const INVOICE = 'invoice';
export const TRANSACTION = 'transaction';
export const BUDGET = 'budget';
export const INBOX = 'inbox';
export const LIGHT_INBOX = 'lightInbox';
export const TO_BE_VALIDATED = 'toBeValidated';
export const LIGHT_TO_BE_VALIDATED = 'lightToBeValidated';
export const TO_BE_PAYED = 'toBePayed';
export const LIGHT_TO_BE_PAYED = 'lightToBePayed';
export const TO_BE_CATEGORIZED = 'toBeCategorized';
export const LIGHT_TO_BE_CATEGORIZED = 'lightToBeCategorized';
export const ASSOCIATED = 'toBeAssociated';
export const LIGHT_ASSOCIATED = 'lightToBeAssociated';
export const REMAINING = 'remaining';
export const HOVERING_GREY = 'hoveringGrey';

export const BODY_FONT = 'bodyFont';

export const MENU_WIDTH = '3rem';
export const HEADER_MIN_HEIGHT = '4rem';
export const RIGHT_PAGE_WIDTH = '25%';

export default extendTheme({
  breakpoints: createBreakpoints({
    sm: '48em', // portrait tablets
    md: '64em', // landing tablets
    lg: '75em', // desktop 1200px
    xl: '90em', // desktop 1440px
    '2xl': '100em', // desktop 1600px
  }),
  colors: {
    [PRIMARY]: '#0a0a3c', // Dark blue
    [PRIMARY_LIGHTER]: '#47476d', // Dark blue, slightly lighter
    [SECONDARY]: '#42e9af', // light green
    [SECONDARY_LIGHTER]: '#a1f4d7', // light green
    [LIGHT]: '#fff',
    [DARK]: '#000',
    [DANGER]: '#dc3545',
    [DANGER_LIGHTER]: '#E7737E',
    [SUCCESS]: '#198754',
    [SUCCESS_LIGHTER]: '#5FAC88',
    [INFO]: '#0dcaf0',
    [INFO_LIGHTER]: '#57DAF5',
    [WARNING]: '#ffc107',
    [WARNING_LIGHTER]: '#FFD453',
    [DARK_GREY]: '#444',
    [MID_GREY]: '#888',
    [LIGHT_MID_GREY]: '#bbb',
    [LIGHT_GREY]: '#eee',
    [INVOICE]: '#26a',
    [TRANSACTION]: '#fb0',
    [BUDGET]: '#f73',
    [INBOX]: '#3F5C9A',
    [LIGHT_INBOX]: '#d4ddf0',
    [TO_BE_VALIDATED]: '#C9672E',
    [LIGHT_TO_BE_VALIDATED]: '#FCE0D0',
    [TO_BE_PAYED]: '#C65D21',
    [LIGHT_TO_BE_PAYED]: '#FFF0C5',
    [TO_BE_CATEGORIZED]: '#C42525',
    [LIGHT_TO_BE_CATEGORIZED]: '#FAD0D4',
    [ASSOCIATED]: '#638B4A',
    [LIGHT_ASSOCIATED]: '#DCEBD4',
    [REMAINING]: '#DDEAF7',
    [HOVERING_GREY]: '#fafafa',
  },
  [BODY_FONT]: '"Open Sans", sans-serif',
  fonts: {
    heading: '"Open Sans", sans-serif',
    body: '"Open Sans", sans-serif',
  },
  textStyles: {
    h1: {
      fontSize: ['3rem', '3.5rem'],
      fontWeight: 'bold',
    },
    h2: {
      fontSize: ['2.5rem', '3rem'],
      fontWeight: 'semibold',
    },
  },
  layerStyles: {
    shadowed: {
      boxShadow: '0 0 .5em rgba(0,0,0,.2)',
    },
    innerShadowed: {
      boxShadow: 'inset .1em .1em .5em rgba(0,0,0,.2)',
    },
  },
  components: {
    Popover: {
      variants: {
        large: {
          popper: {
            maxWidth: '35rem',
            width: '34rem',
          },
        },
      },
    },
    Button: {
      variants: {
        primary: {
          bg: PRIMARY,
          color: LIGHT,
          border: '1px',
          borderColor: PRIMARY,
          borderRadius: 'md',
          _hover: {
            bg: PRIMARY_LIGHTER,
            _disabled: {
              bg: PRIMARY,
            },
          },
          _active: {
            bg: PRIMARY_LIGHTER,
          },
        },
        secondary: {
          bg: SECONDARY,
          color: PRIMARY,
          border: '1px',
          borderColor: SECONDARY,
          borderRadius: 'md',
          _hover: {
            bg: SECONDARY_LIGHTER,
            _disabled: {
              bg: SECONDARY,
            },
          },
          _active: {
            bg: SECONDARY_LIGHTER,
          },
        },
        danger: {
          bg: DANGER,
          color: LIGHT,
          border: '1px',
          borderColor: DANGER,
          borderRadius: 'md',
          _hover: {
            bg: DANGER_LIGHTER,
            _disabled: {
              bg: DANGER,
            },
          },
          _active: {
            bg: DANGER_LIGHTER,
          },
        },
        success: {
          bg: SUCCESS,
          color: LIGHT,
          border: '1px',
          borderColor: SUCCESS,
          borderRadius: 'md',
          _hover: {
            bg: SUCCESS_LIGHTER,
            _disabled: {
              bg: SUCCESS,
            },
          },
          _active: {
            bg: SUCCESS_LIGHTER,
          },
        },
        info: {
          bg: INFO,
          color: DARK,
          border: '1px',
          borderColor: INFO,
          borderRadius: 'md',
          _hover: {
            bg: INFO_LIGHTER,
            _disabled: {
              bg: INFO,
            },
          },
          _active: {
            bg: INFO_LIGHTER,
          },
        },
        warning: {
          bg: WARNING,
          color: DARK,
          border: '1px',
          borderColor: WARNING,
          borderRadius: 'md',
          _hover: {
            bg: WARNING_LIGHTER,
            _disabled: {
              bg: WARNING,
            },
          },
          _active: {
            bg: WARNING_LIGHTER,
          },
        },
        invoice: {
          bg: INVOICE,
          color: LIGHT,
          border: '1px',
          borderColor: INVOICE,
          borderRadius: 'md',
          _hover: {
            bg: LIGHT,
            color: INVOICE,
          },
          _active: {
            bg: LIGHT,
            color: INVOICE,
          },
        },
        transaction: {
          bg: TRANSACTION,
          color: LIGHT,
          border: '1px',
          borderColor: TRANSACTION,
          borderRadius: 'md',
          _hover: {
            bg: LIGHT,
            color: TRANSACTION,
          },
          _active: {
            bg: LIGHT,
            color: TRANSACTION,
          },
        },
      },
    },
    Switch: {
      variants: {
        primary: {
          _checked: {
            bg: PRIMARY,
          },
        },
      },
    },
  },
});
